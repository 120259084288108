import React, { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { InfoContext } from '../components/context';



export default function Landing() {

    const { name, bankIdName, data, mailData, parterList } = useContext(InfoContext);
    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }, [])

    const getAttachmentLength = (id) => {
        let count = data.map(mdata => {
            if (mdata.maildata.id === id)
                return mdata.attachmentlist.length;
        })
        return count;
    }

    return (
        <>
            {!name && <div className="loading" aria-label="Vennligst vente."></div>}
            {name && <div>
                <h3 className="medium-header">Hei {name},</h3>
                {bankIdName !== "" && <p >Vennligst trykk <Link className='semiBold color-blue' to="ny-sak">her</Link> hvis du ønsker å melde ny sak.</p>}
                <br />
            </div>}

            {data && mailData &&
                <div className='overflow-x-auto'>
                    <div className="table" role="table" aria-label="Sendt post.">
                        <div className="table__row table__row__header" role="row">
                            <div className="table__cell title" role="columnheader">Dato</div>
                            <div className="table__cell title" role="columnheader">Sak</div>
                            <div className="table__cell title" role="columnheader">Emne</div>
                            <div className="table__cell title" role="columnheader">Vedlegg</div>
                            <div className="table__cell title" role="columnheader" aria-hidden="true">Åpne</div>
                        </div>
                        {mailData && mailData.map((item, index) => {
                            return (
                                <Link className="table__row" role="row" key={index} to={`/melding/${item.id}`} aria-label="Trykk for å lese dokumentet.">
                                    <div className="table__cell" role="cell">{item.dateReceived.split("T")[0]}</div>
                                    <div className="table__cell" role="cell">{item.claimid}</div>
                                    <div className="table__cell" role="cell">{item.mailsubject}</div>
                                    <div className="table__cell" role="cell">{getAttachmentLength(item.id)}</div>
                                    <div className="table__cell" role="cell" aria-hidden="true"><span className="action-button action-button__show"></span></div>
                                </Link>
                            )
                        })}

                    </div>
                </div>
            }
            <br /><br />
            {parterList && <>
                <p>Klikk for å laste opp dokumenter eller sende meldinger</p>
                <div className='overflow-x-auto'>
                    <div className="table" role="table" aria-label="Document list">
                        <div className="table__row table__row__header" role="row">
                            <div className="table__cell title" role="columnheader">Saksnummer</div>
                            <div className="table__cell title" role="columnheader">Navn</div>
                            {/* <div className="table__cell title" role="columnheader">SystemID</div> */}
                            <div className="table__cell title" role="columnheader">Epost</div>
                            <div className="table__cell title" role="columnheader" aria-hidden="true">Svare</div>
                            {/* {bankIdName !== "" && <div className="table__cell title" role="columnheader" aria-hidden="true">Samtykke</div>} */}
                        </div>
                        {parterList && parterList.map((item, index) => {
                            return (
                                <div className="table__row" role="row" key={index} >
                                    <span className="table__cell" role="cell">{item.claimNumber}</span>
                                    <span className="table__cell" role="cell">{item.navn}</span>
                                    {/* <span className="table__cell" role="cell">{item.systemID}</span> */}
                                    <span className="table__cell" role="cell">{item.epost}</span>
                                    <Link className="table__cell table-link" role="cell" to={`/upload/${item.claimNumber}`} >Svar</Link>
                                    {/* {bankIdName !== "" && <Link className="table__cell table-link" role="cell" to={`/fullmakt/${item.claimNumber}`}>Samtykke</Link>} */}
                                </div>
                            )
                        })}
                        <br />
                    </div>
                    <br />
                </div>
            </>
            }
        </>
    )
}
