import React, { useContext, useEffect, useState } from 'react'
import { InfoContext } from '../components/context'
import Field from '../components/custom/Field'
import CheckboxField from '../components/custom/CheckboxField'
import { useParams, Link, useNavigate } from 'react-router-dom';
import { postFullmakt } from '../js/api-service';


export default function Attorny() {

    const [validCheck, setValidCheck] = useState(false);
    const [loading, setLoading] = useState(false);
    let { id } = useParams();
    const { name, personNumber, session, fullmaktFormData, bankIdName, parterList } = useContext(InfoContext);
    let parterItem = parterList?.find(p => p.claimNumber === id);
    const navigate = useNavigate();
    console.log()
    useEffect(() => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        if (bankIdName === "") { navigate("/") }
    }, [bankIdName])

    const handleUploadSubmit = async (event) => {
        event.preventDefault();
        if (event.target.checkValidity()) {
            setLoading(true)
            const pdfInput = {
                name,
                personalNumber: personNumber,
                clientName: parterItem.clientName,
                claimNumber: parterItem.claimNumber,
                systemId: parterItem.systemId,
                adjusterId: parterItem.adjusterID,
                dbName: "no",
                templateName: bankIdName,
                email: fullmaktFormData["epost"],
                arcValueList: getArcValues()
            }
            const fullmaktUrl = await postFullmakt(session, pdfInput);
            if (fullmaktUrl) {
                window.location.href = fullmaktUrl;
            }
        } else {
            const formInvalidElements = event.target.querySelectorAll('input:invalid, textarea:invalid, select:invalid');
            if (formInvalidElements?.length > 0) {
                formInvalidElements[0].focus();
            }
            setValidCheck(true)
            return;
        }
    }

    const getArcValues = () => {
        switch (bankIdName.toLowerCase()) {
            case "crawforddk":
                return ["urn:grn:authn:dk:mitid:low", "urn:grn:authn:dk:mitid:substantial", "urn:grn:authn:dk:mitid:high", "urn:grn:authn:dk:mitid:business"]
            case "crawfordse":
                return ["urn:grn:authn:se:bankid:same-device", "urn:grn:authn:se:bankid:another-device", "urn:grn:authn:se:bankid:another-device:qr"]
            default:
                return ["urn:grn:authn:no:bankid", "urn:grn:authn:no:bankid:substantial"]
        }
    }

    return (
        <> {loading ? <div className="loading" aria-label="Vennligst vente" ></div > : <div className='form-container '>
            <form name="fullmaktForm" onSubmit={(event) => { handleUploadSubmit(event) }} noValidate >
                <h3 className='medium-header'>Samtykke skjema</h3>
                <Field item={{ formName: "fullmaktForm", name: "navn", type: "text", value: name, required: true, isValidCheck: validCheck, label: "Navn", disabled: true }} />
                <Field item={{ formName: "fullmaktForm", name: "epost", type: "email", placeholder: "E-post", required: true, isValidCheck: validCheck, label: "Skriv inn gyldig e-post adresse" }} />
                <Field item={{ formName: "fullmaktForm", name: "personNummer", type: "text", value: personNumber ?? "", required: true, isValidCheck: validCheck, label: "Personnummer", disabled: true }} />
                <Field item={{ formName: "fullmaktForm", name: "saksNummer", type: "text", value: parterItem.claimNumber, required: true, isValidCheck: validCheck, label: "SaksNummer", disabled: true }} />

                {/* <h5 className='small-header'>Samtykke</h5>
                <CheckboxField item={{ formName: "fullmaktForm", name: "sensitiveInformasjonSamtykke", label: "Jeg samtykke å gi og motta informasjon (inkludert personlig sensitiv informasjon/dokumenter/data)", required: true, isValidCheck: validCheck }} />
                <CheckboxField item={{ formName: "fullmaktForm", name: "vurderingSamtykke", label: "Jeg samtykke å motta vedtak/vurderinger fra Crawford Danmark A/S", required: true, isValidCheck: validCheck }} />
                <CheckboxField item={{ formName: "fullmaktForm", name: "klageSamtykke", label: "Jeg samtykke å klage på vedtak/vurderinger fra Crawford", required: true, isValidCheck: validCheck }} /> */}

                <button type='submit' className='submit-button margin-top__large padding-small'>Send inn</button>
            </form>
            <div className="link-block">
                <Link to="/">Gå tilbake</Link>
            </div>
        </div>
        }</>
    )
}